* , *:before, *:after{
  box-sizing:border-box;
  -moz-box-sizing:border-box;
  -webkit-box-sizing:border-box;
  -ms-box-sizing:border-box;
}

html,
body {
  margin: 0;
  padding: 0px;
  width: 100%;
  height: 100%;
  font-family: 'Graphik', 'Helvetica', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}
